import { Navigate, useSearchParams } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { INTERNAL_ROUTE, LOCAL_STORAGE_KEY } from "src/utils/constants/Enums";
import { InteractionStatus } from "@azure/msal-browser";
import { GeneralLoader } from "src/components";
import { useEffect } from "react";

export default function Auth({ children }: { children: React.ReactNode }) {
  const { inProgress } = useMsal();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");

  useEffect(() => {
    if (
      window.location.pathname === INTERNAL_ROUTE.KULCSUZLET_DASHBOARD &&
      companyId
    ) {
      localStorage.setItem(LOCAL_STORAGE_KEY.KS_BD_GUID, companyId);
    }
  }, [companyId]);

  return inProgress === InteractionStatus.None ? (
    <>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Navigate to={INTERNAL_ROUTE.WELCOME} />
      </UnauthenticatedTemplate>
    </>
  ) : (
    <GeneralLoader isOpen />
  );
}
