import type { AppDispatch } from "src/store/store";
import { useDispatch } from "react-redux";
import {
  Card,
  CardContentBarChartWithLabels,
  CardHeader,
  IncomeModal,
  P,
} from "src/components";
import cn from "classnames";
import { getDateLabel, renderTestId } from "src/utils/HelperFunctions";
import { useCard } from "src/store/slices/cardsSlice";
import { Fragment } from "react";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { useIncomeCard } from "src/store/slices/incomeCardSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";

const CARD_ID = "4f89bd1c-f8d0-40de-be6b-21c95f97bd4e";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  span?: 1 | 2 | 3 | 4 | 6;
  hasModal?: boolean;
}

const IncomeCard: React.FC<Props> = ({
  className,
  span = 4,
  hasModal = true,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );

  const chartIntervals = useIncomeCard();

  const openModal = () => {
    !isEmpty && hasModal && dispatch(setModalOpen(cardConfig[CARD_ID].modalId));
  };

  return (
    <Fragment>
      <Card
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        className={className}
        header={
          <CardHeader
            title={cardConfig[CARD_ID]?.title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card?.updatedAt)}
                </P>
              </div>
            }
            additional={
              <div className="flex items-baseline gap-4">
                {chartIntervals.startDate}
                <span>-</span>
                {chartIntervals.endDate}
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        span={span}
        variant="highlighted"
        content={
          <CardContentBarChartWithLabels
            data={card?.data}
            span={span}
            isEmpty={isEmpty}
            isNotLoaded={isNotLoaded}
          />
        }
        isEmpty={isEmpty}
        hasModal={hasModal}
      />
      {isOpen && <IncomeModal />}
    </Fragment>
  );
};

export default IncomeCard;
