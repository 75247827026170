import { useCard } from "src/store/slices/cardsSlice";
import { AppDispatch } from "src/store/store";
import type { ColumnDef } from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  P,
  ReactTable,
  Tabs,
  ClosedInvoicesModal,
} from "src/components";
import {
  getDateLabel,
  getDisplayValue,
  renderTestId,
} from "src/utils/HelperFunctions";
import cn from "classnames";
import { Fragment, useEffect, useState } from "react";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

type DataItem = {
  product: string;
  invoices: string;
  sum: string;
  uom: string;
};

type DataList = Record<string, DataItem[]>;

const columnHelper = createColumnHelper<DataItem>();
const columnClass = "text-[12px] leading-[12px] font-500 text-primary/60";

const getColumns = (isEmpty = false) =>
  [
    columnHelper.accessor("product", {
      header: () => <P className={cn(columnClass, "pr-3")}>Termék</P>,
      cell: (row) => (
        <P
          className={cn(
            "line-clamp-1 pr-3 break-all",
            row.getValue() === "---" && "text-transparent"
          )}
        >
          {row.getValue()}
          <span className=" tooltip-contents">{row.getValue()}</span>
        </P>
      ),
    }),
    columnHelper.accessor("invoices", {
      header: () => (
        <P className={cn(columnClass, "!whitespace-normal px-3")}>
          Számlák száma
        </P>
      ),
      cell: (row) =>
        isEmpty ? (
          <P className="!font-700 !text-primary/[0.2]">000</P>
        ) : (
          <P className="px-3">{row.getValue()}</P>
        ),
    }),
    columnHelper.accessor("sum", {
      header: () => (
        <P className={cn(columnClass, "!whitespace-normal text-right px-3")}>
          Érték
        </P>
      ),
      cell: (row) => (
        <div className="line-clamp-1 flex gap-2 justify-end px-3">
          {isEmpty ? (
            <P className="line-clamp-1 font-700 text-primary/[0.2]">
              000 000 000
            </P>
          ) : (
            <>
              <P className="line-clamp-1 font-700">
                {getDisplayValue(row.getValue(), row.row.original.uom, true)}
              </P>
              <P className="opacity-60 font-500">{row.row.original.uom}</P>
            </>
          )}
        </div>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

const CARD_ID = "d142537b-d201-4a9f-a89f-9fd1a1452ea2";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cardVariant?: "default" | "secondary";
  hasModal?: boolean;
}

const ClosedInvoicesCard: React.FC<Props> = ({
  cardVariant = "default",
  hasModal = true,
}) => {
  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );
  const dispatch = useDispatch<AppDispatch>();
  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const [groupedData, setGroupedData] = useState<DataList | undefined>();

  const groupData = (
    originalData: DataPointApplicationFeaturesCardsQueriesDataResponse[]
  ): DataList => {
    return originalData.reduce<DataList>((result, dataItem) => {
      const dataListKey = dataItem.displayText || "-";

      if (dataItem.values && dataItem.values.length > 0) {
        const transformedValues = dataItem.values.map((value) => {
          const filledValue = [
            value[0] || "",
            value[1] || "",
            value[2] || "",
            value[3] || "",
            value[4] || "",
          ];

          return {
            product: filledValue[0],
            invoices: filledValue[1],
            sum: filledValue[3],
            uom: filledValue[4],
          };
        });

        while (transformedValues.length < 3) {
          transformedValues.push({
            product: "---",
            invoices: "",
            sum: "",
            uom: "",
          });
        }

        result[dataListKey] = transformedValues;
      }
      return result;
    }, {});
  };

  useEffect(() => {
    if (
      (card?.data && card.data[0] && card.data[0].values && !isEmpty) ||
      (card?.data && !groupedData)
    ) {
      setGroupedData(groupData(card.data));
    }
  }, [card]);

  const tabs =
    groupedData &&
    Object.entries(groupedData).map(([key, data]) => ({
      label: key ?? "",
      content: (
        <ReactTable
          key={key}
          className="h-[113px]"
          data={data}
          columns={getColumns(isEmpty)}
          wrapCells
        />
      ),
    }));

  if (!card) return null;

  const openModal = () =>
    !isEmpty && hasModal && dispatch(setModalOpen(cardConfig[CARD_ID].modalId));
  return (
    <Fragment>
      <Card
        variant={cardVariant}
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        header={
          <CardHeader
            title={cardConfig[CARD_ID].title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card?.updatedAt)}
                </P>
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        content={
          <CardContent
            isLocked={false}
            isEmpty={isEmpty}
            isNotLoaded={isNotLoaded}
          >
            <Tabs data={tabs} />
          </CardContent>
        }
        isEmpty={isEmpty}
        hasModal={hasModal}
      />
      {isOpen && <ClosedInvoicesModal />}
    </Fragment>
  );
};

export default ClosedInvoicesCard;
