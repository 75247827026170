import { useEffect, useState } from "react";
import { useCard } from "src/store/slices/cardsSlice";
import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  P,
  ReactTable,
  MinimumStockProductsModal,
} from "src/components";
import cn from "classnames";
import {
  getDateLabel,
  getDisplayValue,
  renderTestId,
} from "src/utils/HelperFunctions";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { getCardOrEmptyState } from "src/utils/transformers/cardDataTransformer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store/store";
import { setModalOpen, useModal } from "src/store/slices/modalSlice";
import { cardConfig } from "src/utils/constants/CardConfig";
import { DataPointApplicationFeaturesCardsQueriesDataResponse } from "src/store/serviceApi";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cardVariant?: "default" | "secondary";
}

const CARD_ID = "3943b20c-a7ea-4bc2-b0c9-3e777aff70eb";

type DataItem = {
  product: string;
  minValue: string;
  minValueUom: string;
  value: string;
  valueUom: string;
};

const columnHelper = createColumnHelper<DataItem>();
const columnClass =
  "!text-[12px] !leading-[12px] !font-500 text-primary/60 md:!text-nowrap";

const getColumns = (isEmpty = false) =>
  [
    columnHelper.accessor("product", {
      header: () => <P className={cn(columnClass, "pr-3")}>Termék</P>,
      cell: (row) => (
        <P
          className={cn(
            "line-clamp-1 pr-3 break-all",
            row.getValue() === "---" && "text-transparent"
          )}
        >
          {row.getValue()}
          <span className="tooltip-contents">{row.getValue()}</span>
        </P>
      ),
    }),
    columnHelper.accessor("minValue", {
      header: () => (
        <P className={cn(columnClass, "!whitespace-normal md:px-3")}>
          Minimum mennyiség
        </P>
      ),
      cell: (row) => (
        <div className="line-clamp-1 flex gap-2 md:px-3">
          {isEmpty ? (
            <P className={cn("font-700 text-primary/[0.2]")}>000</P>
          ) : (
            <>
              <P className={cn("font-700")}>
                {getDisplayValue(
                  row.row.original.minValue,
                  row.row.original.minValueUom,
                  true
                )}
              </P>
              <P className="opacity-60 font-500">
                {row.row.original.minValueUom}
              </P>
            </>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("value", {
      header: () => (
        <P className={cn(columnClass, "!whitespace-normal text-right pl-3")}>
          Aktuális mennyiség
        </P>
      ),
      cell: (row) => (
        <div className="line-clamp-1 flex gap-2 justify-end">
          {isEmpty ? (
            <P className="font-700 text-primary/[0.2]">000</P>
          ) : (
            <>
              <P className="line-clamp-1 font-700">
                {getDisplayValue(
                  row.row.original.value,
                  row.row.original.valueUom,
                  true
                )}
              </P>
              <P className="opacity-60 font-500">{row.row.original.valueUom}</P>
            </>
          )}
        </div>
      ),
    }),
  ] as ColumnDef<unknown, any>[];

const MinimumStockProductsCard: React.FC<Props> = ({
  cardVariant = "default",
}) => {
  const { isEmpty, card, isNotLoaded } = getCardOrEmptyState(
    CARD_ID,
    useCard(CARD_ID)
  );

  const isMinimumStockListEmpty =
    card.data && card.data[0].values?.length === 0;

  const isOpen = useModal(cardConfig[CARD_ID].modalId) ?? false;
  const dispatch = useDispatch<AppDispatch>();
  const [groupedData, setGroupedData] = useState<DataItem[] | undefined>();

  function groupData(
    originalData: DataPointApplicationFeaturesCardsQueriesDataResponse[]
  ): DataItem[] {
    const productInfo = originalData[0];

    if (!productInfo) return [];

    const values = productInfo.values ?? [];
    const rows: DataItem[] = values.map((value) => {
      return {
        product: value[0] || "",
        minValue: value[1],
        minValueUom: value[2] || "",
        value: value[3],
        valueUom: value[4] || "",
      };
    });
    while (rows.length < 3) {
      rows.push({
        product: "---",
        minValue: "",
        minValueUom: "",
        value: "",
        valueUom: "",
      });
    }
    return rows;
  }

  const openModal = () => {
    !isEmpty && dispatch(setModalOpen(cardConfig[CARD_ID].modalId));
  };

  useEffect(() => {
    if ((card?.data && !isEmpty) || (card?.data && !groupedData)) {
      setGroupedData(groupData(card.data));
    }
  }, [card]);

  return (
    <>
      <Card
        variant={cardVariant}
        {...renderTestId(cardConfig[CARD_ID].testId)}
        onClick={openModal}
        header={
          <CardHeader
            title={cardConfig[CARD_ID].title}
            subtitle={
              <div className={cn("flex", "items-center", "gap-0.5")}>
                <P className={cn("text-xs", "font-400", "opacity-70")}>
                  Frissítve
                </P>
                <P className={cn("text-xs", "font-500", "opacity-80")}>
                  {getDateLabel(card.updatedAt)}
                </P>
              </div>
            }
            info={cardConfig[CARD_ID].infoText}
            icon={cardConfig[CARD_ID].icon}
          />
        }
        content={
          <>
            {isMinimumStockListEmpty && (
              <div className="flex w-full justify-end gap-x-2">
                <P className="text-xs font-400 text-font-main/[0.6]">
                  Minden termék minimum készlet felett
                </P>
                <div className="flex rounded-full p-[3px] bg-green">
                  <Icon icon={"checkmark"} color="white" size={10}></Icon>
                </div>
              </div>
            )}
            <CardContent
              isLocked={false}
              isEmpty={isEmpty}
              isNotLoaded={isNotLoaded}
            >
              <ReactTable
                {...renderTestId(`${cardConfig[CARD_ID].testId}-table`)}
                data={groupedData ?? []}
                columns={getColumns(isEmpty)}
                className="h-[145px]"
                wrapCells
              />
            </CardContent>
          </>
        }
        isEmpty={isEmpty}
      />
      {isOpen && <MinimumStockProductsModal />}
    </>
  );
};

export default MinimumStockProductsCard;
