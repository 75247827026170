import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  CompanyIdentification,
  CompanyIdentificationInviteThirdParty,
  CompanyIdentificationResult,
  Dashboard,
  Layout,
  Onboarding,
  UserAccessesPage,
  StyleGuide,
  StyleGuideOverlay,
  CompanyIdentificationInviteThirdPartyResult,
  MainLandingPage,
  FlexibillLandingPage,
  PayeeLandingPage,
  KulcsUserLandingPage,
  CompanyIdentificationByThirdParty,
  Invite,
  CompanyIdentificationByThirdPartyCancel,
  LogoutPage,
  KulcsUzletDashboard,
} from "src/components";
import Auth from "src/utils/auth/Auth";
import { INTERNAL_ROUTE } from "src/utils/constants/Enums";
import CompaniesPage from "./components/pages/companies/CompaniesPage";
import PageNotFound from "./components/pages/404/PageNotFound";

const router = createBrowserRouter([
  {
    path: INTERNAL_ROUTE.START,
    element: (
      <Auth>
        <Layout hasFooter className="bg-primary/5">
          <Dashboard />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.DASHBOARD,
    element: (
      <Auth>
        <Layout hasFooter className="bg-primary/5">
          <Dashboard />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.KULCSUZLET_DASHBOARD,
    element: (
      <Auth>
        <KulcsUzletDashboard />
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.WELCOME,
    element: (
      <Layout hasFooter>
        <MainLandingPage />
      </Layout>
    ),
  },
  {
    path: INTERNAL_ROUTE.LOGOUT,
    element: (
      <Auth>
        <Layout hasFooter>
          <LogoutPage />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.FLEXIBILL,
    element: (
      <Layout hasFooter>
        <FlexibillLandingPage />
      </Layout>
    ),
  },
  {
    path: INTERNAL_ROUTE.PAYEE,
    element: (
      <Layout hasFooter>
        <PayeeLandingPage />
      </Layout>
    ),
  },
  {
    path: INTERNAL_ROUTE.KULCSUSER,
    element: (
      <Layout hasFooter>
        <KulcsUserLandingPage />
      </Layout>
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANY_IDENTIFICATION,
    element: (
      <Auth>
        <Layout className="bg-primary/5">
          <CompanyIdentification />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY,
    element: (
      /* <Auth> */
      <Layout className="bg-primary/5">
        <CompanyIdentificationByThirdParty />
      </Layout>
      /* </Auth> */
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANY_IDENTIFICATION_BY_THIRD_PARTY_CANCELLED,
    element: (
      /* <Auth> */
      <Layout className="bg-primary/5">
        <CompanyIdentificationByThirdPartyCancel />
      </Layout>
      /* </Auth> */
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANY_IDENTIFICATION_RESULT,
    element: (
      <Auth>
        <Layout className="bg-primary/5">
          <CompanyIdentificationResult />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY,
    element: (
      <Auth>
        <Layout className="bg-primary/5">
          <CompanyIdentificationInviteThirdParty />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANY_IDENTIFICATION_INVITE_THIRD_PARTY_RESULT,
    element: (
      <Auth>
        <Layout className="bg-primary/5">
          <CompanyIdentificationInviteThirdPartyResult />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.INVITE,
    element: (
      <Auth>
        <Layout className="bg-primary/5">
          <Invite />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.USERS,
    element: (
      <Auth>
        <Layout className="bg-primary/5" hasFooter>
          <UserAccessesPage />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.COMPANIES,
    element: (
      <Auth>
        <Layout className="bg-primary/5" hasFooter>
          <CompaniesPage />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.ONBOARDING,
    element: (
      <Auth>
        <Layout className="bg-primary/5">
          <Onboarding />
        </Layout>
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.STYLEGUIDE,
    element: (
      <Auth>
        <StyleGuide />
      </Auth>
    ),
  },
  {
    path: INTERNAL_ROUTE.STYLEGUIDE_OVERLAY,
    element: (
      <Auth>
        <StyleGuideOverlay />
      </Auth>
    ),
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
