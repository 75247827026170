import { Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
    authority:
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_LOGIN_FLOW +
        "/") as string,
    redirectUri: (process.env.REACT_APP_SELF_BASE_URL as string) + "/",
    knownAuthorities: [
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_LOGIN_FLOW +
        "/oauth2/v2.0/authorize") as string,
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_SIGNUP_FLOW +
        "/oauth2/v2.0/authorize") as string,
      ("https://kulcssoftextidentity.b2clogin.com/kulcssoftextidentity.onmicrosoft.com/" +
        process.env.REACT_APP_B2C_CHANGEPASSWORD_FLOW +
        "/oauth2/v2.0/authorize") as string,
    ],
    postLogoutRedirectUri: process.env.REACT_APP_SELF_BASE_URL,
  },
  cache: {
    cacheLocation: "localStorage",
    // storeAuthStateInCookie: true,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

// Authentication Parameters
export const loginRequest = {
  scopes: [
    "openid",
    "offline_access",
    `${process.env.REACT_APP_AUDIENCE}/DataPoint.All`,
  ],
  redirectStartPage: process.env.REACT_APP_SELF_BASE_URL + "/dashboard",
};
